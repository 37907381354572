<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  if (this.currNode.type == '1001') {
                    this.$refs.xGrid.insertEvent();
                  } else {
                    this.$message.error('请选择小区')
                  }
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: 'discountName',
            title: '折扣名称',
            minWidth: 160,
            editRender: {
              name: '$input',
              props: {
                maxlength: 24,
                placeholder: "请输入折扣名称"
              }
            }
          },
          {
            field: "discountType",
            title: "折扣类型",
            minWidth: 120,
            align: "center",
            editRender: {
              name: "$select",
              optionTypeCode: "discountType",
              props: {
                placeholder: "请选择折扣类型"
              }
            },
          },
          {
            field: "discountDetail",
            title: "折扣值",
            minWidth: 120,
            formatter: ({ cellValue, row, column }) => {
              if (row["discountType"] == "1001") {
                return `${this.$XEUtils.commafy(Number(cellValue), { digits: 2 })}%`
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
            editRender: {
              name: "$input",
              enabled: false,
              props: {
                type: "float",
                min: 0,
                max: 999,
                placeholder: "请输入折扣值"
              }
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons"
            },
          },
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "parentId",
            title: "所属上级",
            span: 24,
            itemRender: {
              name: "$VxeSelectParent",
              expandAll: true,
              props: {
                placeholder: "请选择所属上级",
              },
              events: {
                change: (params, node) => {
                  let { data } = params;
                  data["communityId"] = node.id
                }
              }
            },
          },
          {
            field: "discountName",
            title: "折扣名称",
            span: 24,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 32,
                placeholder: "请输入折扣名称",
              },
            },
          },
          {
            field: "discountType",
            title: "折扣类型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "discountType",
              props: {
                placeholder: "请选择折扣类型",
              },
            },
          },
          {
            field: "discountDetail",
            title: "折扣值",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                maxlength: 24,
                placeholder: "请输入折扣值",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          {
            field: 'discountDesc',
            title: '备注',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入备注内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          parentId: [{ required: true, message: "请选择所属上级" }],
          discountName: [{ required: true, message: "请输入打折名称" }],
          discountType: [{ required: true, message: "请选择类型" }],
          discountDetail: [{ required: true, message: "请输入折扣值" }],
          enabled: [{ required: true, message: "请选择状态" }],
        },
        // 新增功能字段初始化
        data: {
          parentId: "",
          discountType: "1001",
          enabled: "1"
        },
      },

      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "discountName",
            title: "折扣名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入折扣名称",
              },
            },
          },
          {
            field: "discountType",
            title: "折扣类型",
            itemRender: {
              name: "$select",
              optionTypeCode: "discountType",
              props: {
                placeholder: "折扣类型",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          discountName: "",
          discountType: "",
        },
      },
    };
  },

  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.formOptions.data["parentId"] = node.id;
      this.formOptions.data["communityId"] = node.id;
      this.searchOptions.data["parentId"] = node.id;
      this.$refs.xGrid.getLists();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
